@font-face {
    font-family:'chaletlondonnineteensixty';
    src: local('chaletlondonnineteensixty'),
    url(https://cdn.shopify.com/s/files/1/0310/7674/5356/t/313/assets/chaletlondonnineteensixty-webfont.woff2) format("woff2"),url(https://cdn.shopify.com/s/files/1/0310/7674/5356/t/313/assets/chaletlondonnineteensixty-webfont.woff) format("woff");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family:'chaletnewyorknineteensixty';
    src: local('chaletnewyorknineteensixty'),
    url(https://cdn.shopify.com/s/files/1/0310/7674/5356/t/313/assets/chaletnewyorknineteensixty-webfont.woff2) format("woff2"),url(https://cdn.shopify.com/s/files/1/0310/7674/5356/t/313/assets/chaletnewyorknineteensixty-webfont.woff) format("woff");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family:'chaletparisnineteensixty';
    src: local('chaletparisnineteensixty'),
    url(https://cdn.shopify.com/s/files/1/0310/7674/5356/t/313/assets/chaletparisnineteensixty-webfont.woff2) format("woff2"),url(https://cdn.shopify.com/s/files/1/0310/7674/5356/t/313/assets/chaletparisnineteensixty-webfont.woff) format("woff");
    font-weight: 400;
    font-style: normal
}
